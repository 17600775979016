import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header/header"
import ScrollUpButton from "react-scroll-up-button"
import "./layout.scss"
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const Layout = ({ children }) => {

	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
		site {
			siteMetadata {
			title
			description
			author
			}
		}
		}
	`)

	return (
		<>
		<Header siteTitle={data.site.siteMetadata.title} />

		{children}

		<ScrollUpButton
			ContainerClassName="stk-back2top"
			TransitionClassName="stk-back2top--visible"
			ShowAtPosition={200}
		>
			<ExpandLessIcon></ExpandLessIcon>
		</ScrollUpButton> 

		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
