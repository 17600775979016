import { Link } from "gatsby"
import React from "react"

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import "./header.scss"

function stkHeaderMobileBg() {
	var stkHeaderMobileElement = document.getElementById("stkHeaderMobile");
	if(stkHeaderMobileElement.classList.contains("navbar--mobile")) {
		stkHeaderMobileElement.classList.remove("navbar--mobile");
	}
	else {
		stkHeaderMobileElement.classList.add("navbar--mobile");
	}
}

const Header = () => (

	<header className="stk-header">

		<Navbar className="stk-header__navbar" id="stkHeaderMobile" expand="lg">
			<Link to="/" className="stk-header__logo">
				<img
					src="/stk-praha-logo-horizontal-white.svg"
					height="30"
					className="stk-header__logo-image"
					alt="STK Praha"
				/>
			</Link>
			<Navbar.Toggle onClick={stkHeaderMobileBg} />
			<Navbar.Collapse>
				<Nav>
					<Link className="stk-header__link" activeClassName="stk-header__link--active" to="/">Úvod</Link>
					<Link className="stk-header__link" activeClassName="stk-header__link--active" to="/tanecni-kurzy" partiallyActive={true}>Taneční kurzy</Link>
					<Link className="stk-header__link" activeClassName="stk-header__link--active" to="/treneri" partiallyActive={true}>Trenéři</Link>
					<Link className="stk-header__link" activeClassName="stk-header__link--active" to="/practise" partiallyActive={true}>Practise</Link>
					<Link className="stk-header__link" activeClassName="stk-header__link--active" to="/o-nas" partiallyActive={true}>O nás</Link>
					<Link className="stk-header__link" activeClassName="stk-header__link--active" to="/kontakt" partiallyActive={true}>Kontakt</Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>

	</header>

)

export default Header